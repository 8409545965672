<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <h1 style="text-transform: uppercase">{{ pageTitle }}</h1>
        <div class="d-flex align-items-center mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
            >Auf dieser Seite können Sie feste Textbausteine verwalten, die in Rechnungen verwendet werden. Diese Bausteine erleichtern das schnelle Einfügen von häufig genutzten Standardtexten, wie beispielsweise Zahlungsbedingungen oder rechtliche Hinweise.
          </span>
          <div class="symbol symbol-50">
            <span class="symbol-label bg-light-light">
              <img
                  :src= "getBasePath() + 'media/svg/icons/General/Thunder-move.svg'"
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </div>
        <!--end::Info-->
        <v-app>
          <v-container>
            <v-row style="margin-top: 25px;">
              <v-col cols="12" class="d-flex justify-end text-center">
                <v-btn
                  class="primary custom_button"
                  :disabled="false"
                  large
                  height="50"
                  style="margin-right: 15px;"
                  @click="dialog = true"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Neuer Textbaustein
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="fixed_texts"
                  :items-per-page="30"
                  sort-by="kürzel"
                  class="table1"
                  loading-text="Lade Textbausteine... Bitte warten"
                  no-data-text="Keine Textbausteine vorhanden"
                  hide-default-footer
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        title="Edit user"
                        class="mr-2"
                        @click="editItem(item)"
                        text="test"
                        style="font-size: 20px;"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                        v-if="item.id != 1"
                        small
                        class="mr-2"
                        @click="removeItem(item)"
                        style="font-size: 18px;"
                        color="red"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <template>
            <v-row justify="center">
              <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                v-model="editedItem.short"
                                :rules="[rules.required, rules.maxLength]"
                                label="Kürzel*"
                                dense
                                auto-grow
                                filled
                                required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                                v-model="editedItem.description"
                                :rules="[rules.required]"
                                label="Beschreibung*"
                                dense
                                auto-grow
                                filled
                                required
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <div v-if="formInvalid" class="fillAllFieldsMessage">
                          {{ formInvalidMessage }}
                        </div>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="secondary p-8" large color="primary darken-1"
                      :loading="loading"
                      text
                      @click="dialog = false"
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                        class="primary p-8" large
                      :loading="loading"
                      text
                      @click="save()"
                    >
                      Senden
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
        </v-app>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import TaxType from "../../../stub/taxtype";

export default {
  name: "fixed-text",
  data: function() {
    return {
      pageTitle: "Feste Textblöcke",
      headers: [
        { text: "Kürzel", value: "short" },
        { text: "Beschreibung", value: "description" },
        { text: "Optionen", value: "actions", sortable: false }
      ],
      rules: {
        required: value => !!value || "Pflichtfeld.",
        maxLength: v => (v.length <= 20) || 'Maximal 20 Zeichen erlaubt'
      },
      formInvalidMessage: "*Bitte füllen Sie alle Eingabefelder aus",
      formInvalid: false,
      newTaxType: { ...TaxType },
      dialogSaveButton: null,
      dialog: false,
      loading: false,
      fixed_texts: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        short: "",
        description: ""
      },
    };
  },
  created() {
    this.loadingvariable = true;
    ApiService.query("fixed-texts")
      .then(({ data }) => {
        this.fixed_texts = data.data;
        this.loadingvariable = false;
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          this.loadingvariable = false;
          this.$store
            .dispatch(LOGOUT)
            .then(() => this.$router.push({ name: "login" }));
        }
      });
  },
  computed: {
    formTitle() {
      return this.editedIndex != -1
          ? "Textbaustein editieren"
          : "Neuer Textbaustein ";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    getBasePath(){
      return process.env.BASE_URL;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        //this.dialog = true
        /*let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel
        });*/

        if (this.editedIndex > -1) {
          ApiService.put("fixed-texts", this.editedItem)
              .then(response => {
                let fixedText = response.data.data;
                if (fixedText) {
                  Object.assign(this.fixed_texts[this.editedIndex], fixedText);
                  this.resetForm();
                  this.close();
                }
                this.loading = false;
              })
              .catch(response => {
                this.formInvalidMessage = response.data.message;
                this.formInvalid = true;
                this.loading = false;
              });
        } else {
          ApiService.post("fixed-texts", {
            fixedtexts: this.editedItem
          })
              .then(({ data }) => {
                this.fixed_texts.push(data.data);
                //this.newTaxType = { ...TaxType };
                this.loading = false;
                this.dialog = false;
              })
              .catch(() => {
                this.loading = false;
                this.dialog = false;
              });
        }
      }
    },
    removeItem(item) {
      Swal.fire({
        title: "Textbaustein löschen",
        text: "Möchten Sie diesen Textbaustein wirklich löschen?",
        icon: "warning",
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: "Ja",
        cancelButtonText: "Abbrechen"
      }).then(result => {
        if (result.value) {
          ApiService.delete("/fixed-texts/" + item.id)
            .then(() => {
              window.location.reload();
            })
            .catch(({ response }) => {
              if (response.status === 401) {
                if (response.status === 401) {
                  this.$store
                    .dispatch(LOGOUT)
                    .then(() => this.$router.push({ name: "login" }));
                }
              }
              this.loadingvariable = false;
            });
        }
      });
    },
    getHeaders(headingText) {
      return [
        {
          text: "Dynamic heading no. " + headingText,
          align: "left",
          sortable: false,
          value: "name"
        }
      ];
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    close() {
      this.dialog = false;
      this.formInvalid = false;
      this.formInvalidMessage = "";
      this.editedItem = {
        id: "",
        short: "",
        description: ""
      };
      this.editedIndex = -1;
    },
    editItem(item) {
      this.editedIndex = this.fixed_texts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    resetForm() {
      this.formInvalid = false;
      for (var key in this.editedItem) {
        this.editedItem[key] = "";
      }
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>

<style scoped></style>
